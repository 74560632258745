.footer-section {
  height: fit-content;
  // background: #e95657;
  // background: #293347;
  background: #000;
  color: #fff;

  padding: 0rem 1.5rem 1rem;
  text-align: center;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 40px;
    position: relative;

    .footer-logo {
      position: absolute;
      left: calc(50% - 25px);
      top: 20px;
      display: block;
      height: 30px;
      color: #fff;
      letter-spacing: -1px;
      z-index: 22;
      width: 55px;
      text-align: left;
      
      div {
        display: inline;
        position: absolute;
        padding-left: 2px;
        top: 4px;
        font-weight: bold;
      }
    }

    .info {
      justify-content: center;

      flex-basis: 0;
      flex-grow: 1;
      flex-shrink: 1;
      padding: 0.75rem;

      opacity: 0.7;
      max-width: 300px;
      
      margin: 1rem auto;
      margin-bottom: .75rem;

      font-weight: 400;
      line-height: 1.125;

      word-break: break-word;
      font-size: 1.5rem;
    }

    .social {
      margin: 0 auto 1rem;
      display: flex;
      align-content: space-between;
      justify-content: center;

      .button {
        background-color: transparent;
        border-radius: 100%;
        margin: 0 0.3rem;
        height: 50px;
        width: 50px;
        border: 2px solid #ffffff99;
        z-index: 22;
        color: #fff;
        transition: all 0.5s;

        .icon {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          padding-top: 0.95rem;
        }
      }

      .button:hover {
        transition: all 0.5s;
        color: #000;
        background-color: #fff;
      }
    }

    .credit {
      opacity: 0.7;
    }
  }
}