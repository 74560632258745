.router-container {
  position: relative;
  background: #000;
  height: 100vh;
  

  @keyframes slideOut {
    0% { }
    25% { opacity: .5; transform: translateZ(-500px); }
    75% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }
    100% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }
  };

  @keyframes slideIn {    
    0%, 25% { opacity: .5; transform: translateZ(-500px) translateX(200%); }
    75% { opacity: .5; transform: translateZ(-500px); }
    100% { opacity: 1; transform: translateZ(0) translateX(0); }
  };

  .slide-exit-active {
    animation: slideOut 1000ms both ease;
  }

  .slide-enter-active {
    animation: slideIn 1000ms both ease;
  }

  .route-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
}