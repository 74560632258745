.home-hero {
  height: calc(100vh - 80px);
  z-index: 101;
  display: flex;
  justify-content: center;
  background: #fff;

  h1 {
    font-size: 18vw;
    letter-spacing: 0px;
    line-height: 110%;
    margin: auto;
    position: relative;
    transition: font-size 500ms;
  }

  @media screen and (min-width: 700px) {
    h1 {
      font-size: 80px;
      transition: font-size 500ms;
    }
  }
}