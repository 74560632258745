/*
██╗  ██╗███████╗ █████╗ ██████╗ ███████╗██████╗ 
██║  ██║██╔════╝██╔══██╗██╔══██╗██╔════╝██╔══██╗
███████║█████╗  ███████║██║  ██║█████╗  ██████╔╝
██╔══██║██╔══╝  ██╔══██║██║  ██║██╔══╝  ██╔══██╗
██║  ██║███████╗██║  ██║██████╔╝███████╗██║  ██║
╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝╚═╝  ╚═╝
*/

$var: #333;
$primary-color: #ef9c2d;


.header-simple {
  margin-bottom: 80px;
  // margin-bottom: 85px;
  // margin-bottom: 110px;

  /* MAKE THIS BETTER */
  position: relative;
  z-index: 5;
}

.header-simple.is-menu-open {
  z-index: 25;
}

.header-wrapper {
  // position: fixed;
  // left: 50%;
  display: flex;
  justify-content: space-between;

  max-width: 900px;
  margin: 0 auto;

  border-bottom: 3px solid #000;
}

.navigation-wrap {
  transform: matrix(1, 0, 0,
    1, 0, 0);

  background: none;
  left: auto;
  /* right: -100%; */
  display: table;
  width: 100%;
  /* height: 100%; */
  height: 100vh;
  border-right: none;

  background: #fff;
  
  position: fixed;
  top: 0;

  opacity: 0;
  transition: all .5s;

  > .in {
    vertical-align: middle;
    display: table-cell;
    width: auto;
    height: auto;

    transition: all 1s;
    opacity: 1;
    border-right: 1px solid #eee;
    box-sizing: border-box;
  }
}

.is-menu-open {

  .navigation-wrap {
    transform: translate3d(0px, 0px, 0px);
    /* right: 0; */

    opacity: 1;
    transition: all .5s;
  }

  .navigation a {
    display: block;
  }

  .navigation-bg {
    opacity: 1;
    transform: scale(1);
    transition: opacity .3s;
  }

  .toggle {
    .t1, .t2, .t3 {
      transform: translate(0px, 9px);
    }

    .t1 {
      transform: translate(4px, 13px);
    }

    .t2 {
      transform: translate(3px, 5px);
    }

    .t3 {
      transform: translate(-2px, 9px);
    }

    .t4, .t5, .t6 {
      // transform: translate(5px, 0px);
    }

    .t4 {
      transform: translate(4px, 0px);
    }

    .t5 {
      transform: translate(-5px, -4px);
    }

    .t6 {
      transform: translate(-10px, -4px);
    }

    .t7, .t8, .t9 {
      // transform: translate(4px, -9px);
    }

    .t7 {
      transform: translate(4px, -1px);
    }
    
    .t8 {
      transform: translate(7px, -13px)
    }

    .t9 {
      transform: translate(-14px, 0px);
    }
  }
}

.navigation {
  max-width: 360px;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  transform: rotate(0deg);
  height: auto;
  font-size: 0;
  margin: 0 auto;
  position: relative;
  z-index: 101;

  text-align: center;
  transform-origin: center;
  flex-direction: row-reverse;
  justify-content: center;
  overflow: hidden;

  li {
    margin: 0;
    display: inline-block;
    vertical-align: top;
    /* transform: translate(0, 0) scale(0.6); */

    width: 100px;
    height: 100px;
    line-height: 100px;

    overflow: hidden;
    position: relative;
  }

  a {
    font-size: 1.1rem;
    font-weight: 500;
    color: #fff;
    box-sizing: border-box;
    padding: 0;
    
    display: none;
    width: 100px;
    height: 100px;
    line-height: 100px;
  }

  .n1 {
    background: #ed7d79;
  }

  .n2 {
    background: #e95657;
  }

  .n3 {
    background: #da4149;
  }
  
  .n4 {
    background: #ef8d88;
  }

  .n5 {
    /* background: #e66c68; */
    background: #111;
  }

  .hook {
    height: 100px;
    width: 100px;
    display: inline-block;

    @for $i from 1 through 9 {
      .t#{$i} {
        display: inline-block;
        height: 33px;
        width: 33px;
        background: #111;
      }
    }

    .t7, .t8, .t9 {
      height: 34px;
    }

    .t3, .t6, .t9 {
      width: 34px;
    }

    .t1 {
      background: #f2a19b;
    }

    .t2 {
      background: #ed7d79;
    }

    .t3 {
      background: #e66c68;
    }
  }

  .n7 {
    background: #f2a19b;
  }

  .n6, .n8, .n9 {
    background: #111; 
  }

  .corner {
    height: 100px;
    width: 100px;
    display: inline-block;

    /* $squares: 9; */
    @for $i from 1 through 9 {
      .d#{$i} {
        display: inline-block;
        height: 33px;
        width: 33px;
      }
    }

    .d1 {
      background: #111;
    }

    .d2 {
      background: #da4149;
    }

    .d4 {
      background: #f2a19b;
    }

    .d5 {
      background: #e95657;
    }

    .d7 {
      background: #ef8d88;

    }

    .d8 {
      background: #ed7d79;
    }

    .d7, .d8, .d9 {
      height: 34px;
    }

    .d3, .d6, .d9 {
      width: 34px;
      background: #111;
    }
  }

}

.navigation.menu-enter {
  .n1, .n2, .n3, .n4, .n5,
  .n6, .n7, .n8, .n9 {
    opacity: 0;
    transform: scale(0);
  }

  @for $i from 1 through 9 {
    .hook .t#{$i} {
      opacity: 0;
      transform: scale(0);
    }
  }

  @for $i from 1 through 9 {
    .corner .d#{$i} {
      opacity: 0;
      transform: scale(0);
    }
  }
}

.navigation.menu-enter-active {

  .n1, .n2, .n3, .n4, .n5,
  .n6, .n7, .n8, .n9 {
    opacity: 1;
    transform: scale(1);
    /* transition: all 500ms cubic-bezier(0.57, 0.07, 0.44, 1.35); */
    transition: all 500ms cubic-bezier(.41,.18,.24,1.29);
  }

  @for $i from 1 through 9 {
    .hook .t#{$i} {
      opacity: 1;
      transform: scale(1);
      transition: all 500ms cubic-bezier(.41,.18,.24,1.29);
    }
  }

  @for $i from 1 through 9 {
    .corner .d#{$i} {
      opacity: 1;
      transform: scale(1);
      transition: all 500ms cubic-bezier(.41,.18,.24,1.29);
    }
  }
}

.navigation.menu-enter-done {
  .n1, .n2, .n3, .n4, .n5,
  .n6, .n7, .n8, .n9 {
    opacity: 1;
  }

  @for $i from 1 through 9 {
    .hook .t#{$i} {
      opacity: 1;
    }
  }

  @for $i from 1 through 9 {
    .corner .d#{$i} {
      opacity: 1;
    }
  }
}

.navigation.menu-exit {
  .n1, .n2, .n3, .n4, .n5,
  .n6, .n7, .n8, .n9 {
    opacity: 1;
    transform: scale(1);
  }

  @for $i from 1 through 9 {
    $time: ($i * 0.02s) + 0.14s; 
    .hook .t#{$i} {
      opacity: 1;
      transform: scale(1);
    }
  }

  @for $i from 1 through 9 {
    .corner .d#{$i} {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.navigation.menu-exit-active {
  .n1, .n2, .n3, .n4, .n5,
  .n6, .n7, .n8, .n9 {
    opacity: 0;
    transform: scale(0);
    transition: all 500ms;
  }

  @for $i from 1 through 9 {
    $time: ($i * 0.02s) + 0.14s; 
    .hook .t#{$i} {
      opacity: 0;
      transform: scale(0);
      transition: all 500ms;
    }
  }

  @for $i from 1 through 9 {
    .corner .d#{$i} {
      opacity: 0;
      transform: scale(0);
      transition: all 500ms;
    }
  }
}


.navigation.menu-enter-active,
.navigation.menu-exit-active {
  .n2 {
    transition-delay: 0.02s;
  }

  .n3 {
    transition-delay: 0.04s;
  }

  .n4 {
    transition-delay: 0.06s;
  }

  .n5 {
    transition-delay: 0.08s;
  }

  /*
  .n6, .hook {
    transition-delay: 0.1s;
  }
  */

  .n7 {
    transition-delay: 0.12s;
  }

  .n8 {
    transition-delay: 0.14s;
  }

  .n9 {
    transition-delay: 0.16s;
  }
}

.navigation.menu-enter-active {
  @for $i from 1 through 9 {
    $time: ($i * 0.02s) + 0.14s; 
    .hook .t#{$i} {
      transition-delay: #{$time};
    }
  }

  @for $i from 1 through 9 {
    $time: ($i * 0.02s) + 0.22s; 
    .corner .d#{$i} {
      transition-delay: #{$time};
    }
  }
}

.navigation.menu-exit-active {
  @for $i from 1 through 9 {
    $time: ($i * 0.01s) + 0.1s; 
    .hook .t#{$i} {
      transition-delay: #{$time};
    }
  }

  @for $i from 1 through 9 {
    $time: ($i * 0.01s) + 0.19s; 
    .corner .d#{$i} {
      transition-delay: #{$time};
    }
  }
}

.navigation-bg {
  background: rgba(255, 255, 255, 0.95);
  box-sizing: border-box;
  height: 100%;
  padding: 2rem;
  position: fixed;
  top: 0;
  transition: opacity .3s;
  width: 100%;
  display: flex;
  flex-direction: column;
  right: 0;
  opacity: 0;
  z-index: 10;
}

.header-bg {
  height: 80px;
  position: fixed;
  width: 100%;
  
  z-index: 99;
  // background: #fff;

  top: 0;
  right: 0;
  left: 0;

  // border-bottom: 3px solid linear-gradient(to bottom, #ffffff,
  // #b9b9bd, #78787f, #3c3d46, #000314);
  
  // border-bottom: 3px solid #000;

  // max-width: 900px;
  margin: 0 auto;
  background: #fff;

  // background: /* gradient can be an image */
  // // linear-gradient(
  // //   to left, 
  // //   rgba(92,7,52,1) 0%,
  // //   rgba(134,29,84,1) 12%,
  // //   rgba(255,93,177,1) 47%,
  // //   rgba(83,0,30,1) 100%
  // // )
  // linear-gradient(to bottom, #ffffff,
  // #b9b9bd, #78787f, #3c3d46, #000314)
  //   left 
  //   bottom
  //   #fff    
  //   no-repeat; 
  // background-size:100% 5px ;
}

// .header-bottom {
//   /* Not working ?? */
//   transition-delay: 0.3s;
//   transition: all 1.3s;

//   height: 3px;
//   position: fixed;
//   width: 100%;
//   top: 80px;
//   z-index: 99;
//   background-image: linear-gradient(to bottom, #ffffff,
//   #b9b9bd, #78787f, #3c3d46, #000314);
// }

.toggle {
  height: 80px;
  width: 80px;

  cursor: pointer;
  // position: fixed;
  // margin-right: 5%;

  z-index: 101;
  right: 0;
  top: 0;
  /*
  width: 100px;
  height: 100px;
  */

  .t {
    transition: all 0.3s;

    position: absolute;
    width: 4px;
    height: 4px;
  }

  .b {
    background: #333;
  }

  .r {
    background: #da4149;
  }

  .t1 {
    transition-delay: 0s;

    top: 0;
    left: 0;
  }

  .t2 {
    transition-delay: 0.02s;

    top: 0;
    left: 50%;
    margin-left: -2px;
  }

  .t3 {
    transition-delay: 0.04s;

    top: 0;
    right: 0;
  }

  .t4 {
    transition-delay: 0.06s;

    top: 50%;
    left: 0;
    margin-top: -2px;
  }

  .t5 {
    transition-delay: 0.08s;
    top: 50%;
    left: 50%;

    margin-top: -2px;
    margin-left: -2px;
  }

  .t6 {
    transition-delay: 0.1s;
    top: 50%;
    right: 0;

    margin-top: -2px;
  }

  .t7 {
    transition-delay: 0.12s;
    bottom: 0;
    left: 0;
  }

  .t8 {
    transition-delay: 0.14s;

    bottom: 0;
    left: 50%;
    margin-left: -2px;
  }

  .t9 {
    transition-delay: 0.16s;
    
    bottom: 0;
    right: 0;
  }
}

.table {
  display: table;
  height: 100%;
  width: 100%;
}

.cell {
  display: table-cell;
  vertical-align: middle;
}

.toggle-in {
  height: 22px;
  width: 22px;
  margin: 0 auto;
}

.in {
  position: relative;
  height: inherit;
  // height: 100%;
  // width: 100%;
}

.logo-wrap {
  opacity: 1;
  left: 30px;
  height: 80px;
  z-index: 101;

  // width: 90px;
  display: block;
  // position: fixed;
  margin-left: 5%;

  top: 0;
  color: #333;
}

@media screen and (max-width: 700px) {
  .logo-wrap {
    width: 50px;
  }
}

.dark-mode-toggle-wrapper {
  display: flex;
  align-items: center;

  .react-toggle {
    .fas {
      bottom: 2px;
      position: relative;
      // color: white;
      color: #efefef;
    }

    .fa-moon {
      bottom: 3px;

      // color: #72b9f7;
      color: #3B4856;
    }
  }

  .react-toggle-track {
    width: 55px;
    // background: black;
    background: #72b9f7;
    // background: #24467d;

    // background: #282449;
    // background: #3B4856;
    // background: #9FADBD;
  }

  .react-toggle:hover .react-toggle-track {
    // background: #9FADBD;
    background: #72b9f7;
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 32px;
    background: #efefef;
  }

  // Overrides for default styling on react-toggle
  .react-toggle-thumb {
    // border-color: black;
    border: none;
    background: #3B4856;
    // border: none;
  }

  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: none;
  }

  .react-toggle--focus .react-toggle-thumb {
    box-shadow: none;
  }

  .react-toggle--checked .react-toggle-track {
    background-color: #da4149;
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #da4149;
  }
}

.logo {
  margin-left: auto;
  margin-right: auto;

  // width: 90px;
  display: block;
  margin-top: 5px;
  letter-spacing: -1px;

  div {
    display: inline;
    position: absolute;
    padding-left: 2px;
    top: 36px;
    font-weight: bold;
  }
}