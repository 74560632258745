* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

body, html {
    /* overflow: hidden; */
    width: 100%;
    height: 100%;
    font-family: Roboto, sans-serif;
}

#root, .page-container {
    height: 100%;
    width: 100%;
}

a {
    text-decoration: none;
    cursor: pointer;
}


/* .page-fade-appear,
.page-fade-enter {
    opacity: 0;
    z-index: 101;
}

.page-fade-appear-active {
    opacity: 1;
    z-index: 101;
    transition: all 1000ms linear;
} */

.page-fade-exit-active {
    animation: slideOut 500ms both ease;
}

.page-fade-appear {
    
}

.page-fade-enter-active,
.page-fade-appear-active {
    animation: slideIn 500ms both ease;
}



@keyframes slideOut {
    0% { }
    25% { opacity: .5; transform: translateZ(-500px); }
    75% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }
    100% { opacity: .5; transform: translateZ(-500px) translateX(-200%); }   
}

@keyframes slideIn {
    0%, 25% { opacity: .5; transform: translateZ(-500px) translateX(200%); }
    75% { opacity: .5; transform: translateZ(-500px); }
    100% { opacity: 1; transform: translateZ(0) translateX(0); }
}
 

/* .page-fade-exit-active {
    opacity: 0;
    z-index: 101;
    transition: all 1000ms linear;
}

.page-fade-exit {
    opacity: 1;
    z-index: 101;
}

.page-fade-enter-active {
    opacity: 0;
    z-index: 101;
    transition: all 1000ms linear;
} */

/*
.app {
    position: relative;
    margin: 8px;
    box-sizing: border-box;
    /* background: #efece9; * /
    background: #e95657;
    height: calc(100% - 16px);
} 

.app .app-text {
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;

    text-align: center;
    font-size: 1.4rem;
}

.app::before {
    position: absolute;
    left: 0;
    top: -10px;
    content: '';
    background: linear-gradient(360deg,
    #e95657, transparent);
    height: 10px;
    width: 100%;
    z-index: 1;
}
*/
