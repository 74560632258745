$red-accent: #da4149;

.about-container {
  background: #fff;
  min-height: 100vh;
  padding-top: 30px;

  .header {
    height: 160px;

    h1 {
      font-weight: bold;
      font-size: 3em;
      margin: 0 auto;
      margin-top: 30px;
      width: fit-content;
      color: #000;
      text-align: center;
    }

    .about-box {
      max-width: 700px;
      background: #dfdfdf;
      min-height: 600px;
      margin: 0 auto;
      margin-top: 50px;
      margin-bottom: 50px;

      padding-bottom: 50px;

      // why does this work...
      overflow: hidden;

      h3 {
        margin: 20px 0;
        font-size: 1.6rem;
        color: #000;
        text-align: center;
      }

      p {
        margin-top: 30px; 
        padding: 0 20px;
        line-height: 1.8em;
      }

      ul {
        padding: 0 20px;
        margin-top: 30px;
        padding-top: 20px;

        li {
          width: 100%;
          margin-bottom: 20px;
          background: #fff;
          list-style: none; 
          border-radius: 4px;

          padding: 1rem;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          cursor: pointer;

          position: relative;
          z-index: 5;
          
          .info-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            h4 {
              text-align: center;
              font-size: 1.4rem;
              width: 100%;
              color: #282449;

              margin-left: -16px;
            }

            .fas {
              color: $red-accent;
            }
  
            .expand {
              display: block;
              position: relative;
              width: 15px;
              height: 15px;
            }
  
            .expand::before, .expand::after {
              content: '';
              position: absolute;
              left: 50%;
              width: 0;
              height: 100%;
              overflow: hidden;
              border-left: 2px solid $red-accent;
              transition: all 1s ease;
            }
  
            .expand::after {
              transform: rotate(90deg);
            }
          }

          .info-content {
            transition: all 700ms ease-in-out;
            transform: scale(0);
            opacity: 0;
            max-height: 0;
            line-height: 1.5rem;

            color: #282449;

            .ic-wrapper {
              padding: 1.3rem;
              padding-left: 2.3rem;
            }
          }
        }

        li.open {
          .expand::before {
            transform: rotate(145deg);
          }

          .expand::after{
            transform: rotate(215deg);
          }

          .info-content {
            transition: all 1s ease;
            position: relative;
            display: flex;
            transform: scale(1);
            opacity: 1;
            height: fit-content;
            max-height: 21rem;
            width: 100%;
          }
        }
      }

      .transform-plus {
        position: relative;
        height: 40px;
        z-index: 5;
      }

      .transform-plus::before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 0;
        height: 100%;
        margin-left: 1px;
        overflow: hidden;
        border-left: 2px solid #000;
        transition: all 1s ease;
      }

      .transform-plus::after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        width: 0;
        height: 100%;
        margin-left: 1px;
        overflow: hidden;
        border-left: 2px solid #000;
        transform: rotate(90deg);
        transition: all 1s ease;
      }

      .transform-plus.change::before {
        transform: rotate(145deg);
      }

      .transform-plus.change::after {
        transform: rotate(215deg);
      }
    } 
  }
}