$dark-pink: #da4149;
$salmon: #e95657;

.white-bg {
  width: 100%;
  background: #fff;
  z-index: -5;
  height: 20vh;
  margin-bottom: -20vh;
}

.intro-section {
  position: relative;
  text-align: center;
  overflow: hidden;
  max-width: 800px;
  margin-left: auto;
  background: #fff;

  .textLeft {
    margin-top: 30px;
    height: 50px;
  }

  .text-area-enter {
    opacity: 0;
    transform: scale(0.35, 0.25) rotateX(15deg) 
      rotateZ(20deg) translateY(150px) 
      translateX(-50px);
  }

  .text-area-enter-active {
    opacity: 1;
    transform: scale(1, 1) rotateX(0deg) 
      rotateZ(0deg) translateY(0) 
      translateX(0);
    transition: all 500ms cubic-bezier(.41,.18,.24,1.29);
  }

  .text-area-exit {
    opacity: 1;
    transform: translateX(0);
  }

  .text-area-exit-active {
    opacity: 0;
    transform: translateX(-80px);
    transition: all 500ms;
  }

  .left-text-enter {
    opacity: 0;
    transform: translateX(-80px);
  }

  .left-text-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 500ms cubic-bezier(.41,.18,.24,1.29);
  }


  .right-text-enter {
    opacity: 0;
    transform: translateX(80px);
  }

  .right-text-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 500ms cubic-bezier(.41,.18,.24,1.29);
  }

  .long-section {
    z-index: -1;

    padding: 2rem 1.5rem;
    
    padding-bottom: 14rem;
    background-color: #e95657;
    color: #fff;
    
    text-align: center;

    border-radius: 60px;
    border-bottom-left-radius: 200px;
    border-bottom-right-radius: 200px;

    // test
    min-height: 650px;

    .container {
      margin: 0 auto;
      position: relative;

      .column {
        display: block;
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;
        padding: 0.75rem;
        min-height: 400px;

        h1 {
          margin-bottom: 1.5rem;
          line-height: 1.125;
          font-size: 1.5rem;
        }

        h2 {
          font-weight: 300;
          line-height: 1.5;
          font-size: 1.2rem;
        }
      }
    }
  }

  .long-section.reverse {
    border-radius: 60px;
    border-top-left-radius: 200px;
    border-top-right-radius: 200px;
    margin-top: -12rem;
    margin-bottom: 4rem;

    padding: 2rem 1.5rem;
    padding-top: 12rem;

    min-height: 650px;

    .column {
      min-height: 200px;
    }
  }

  .skills-section {
    margin-top: -14rem;
    padding: 2rem 1.5rem;
    padding-bottom: 1rem;
    text-align: center;
    margin-bottom: 2rem;

    // testing
    min-height: 2190px;

    .slim-container {
      max-width: 1200px;
      margin: 0 auto;
      position: relative;
      display: flex;
      justify-content: center;

      .box {
        max-width: 600px;
        /* min-width: 300px; */
        min-width: 100%;

        border-radius: 12px;
        padding: 0;

        background-color: #fff;
        box-shadow: 0 5px 5px 0 
          rgba(233,240,243,0.5), 
          0 0 0 1px #E6ECF8;
        color: #282449;

        .columns {
          margin-left: 0;
          margin-right: 0;

          justify-content: center;
          margin-top: -0.75rem;

          .column {
            padding: 50px 30px;
            border-bottom: 1px solid #e6ecf8;
            min-height: 450px;

            h1 {
              margin-top: 1em;
              margin-bottom: 1.5em;
              font-size: 1.5rem;
            }

            .fas {
              color: $dark-pink;
            }

            p {
              font-size: 1.2rem;
              color: $dark-pink;
              margin-top: 50px;
              margin-bottom: 8px;
            }

            p + p, ul + p {
              margin-top: 20px;
            }

            ul {
              list-style: none;
              margin-bottom: 30px;

              li {
                margin-bottom: 8px;
              }
            }

            .info {
              line-height: 1.5;
            }
          }

          .column:last-child {
            border-bottom: none;
          }
        }

        .columns:last-child {
          margin-bottom: -0.75rem;
        }
      }      
    }
  }
}

@media only screen and (min-width: 815px) {
  .intro-section {
    margin: 0 auto;
  }
}